import React from 'react'
import { Section } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import headerJson from '../../header.json'

import { Link } from 'gatsby'

type HeroSectionProps = {
  openModal: () => void;
};

const HeroSection = ({ openModal }: HeroSectionProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <Section id='hero-rlp' className='d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5 pb-5 pb-md-0'>
            <div className='bread'>
              <Link
                to='/'
                className='d-md-inline'
              ><OrangeIcon icon='home' color='#fff' size='MD' />
              </Link>
              <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
              <Link
                to='/pra-voce/investimentos/'
                className='fs-12 fs-lg-14 lh-14 fw-700 text-white d-inline mr-2'
              >
                {headerJson.breadcrumb[0]}
              </Link>
              <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
              <p className='fs-12 fs-lg-14 lh-14 fw-700 text-white d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
            </div>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lg-xl-50 fw-600 text-white mt-3 mb-3'>
              Aumente sua liquidez com <span className='d-md-block d-lg-inline'>o RLP Inter Invest</span>
            </h1>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-white mb-0 pb-lg-2 pb-xl-3'>
              E tenha um retorno mais atrativo nas suas <span className='d-xl-block'>movimentações de renda variável!</span>
            </p>
            <button
              onClick={() => {
                openModal()
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  element_name: ' Abra sua conta agora mesmo',
                  section_name: 'Aumente sua liquidez com o RLP Inter Invest',
                })
              }}
              className='btn btn-white btn--lg fs-14 fw-600 rounded-2 text-orange--extra text-none mt-4 d-none d-md-flex'
            >
              Abra sua conta agora mesmo
            </button>
            <a
              href='https://inter-co.onelink.me/Qyu7/wcys2pri'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  element_name: ' Abra sua conta agora mesmo',
                  section_name: 'Aumente sua liquidez com o RLP Inter Invest',
                  redirect_url: 'https://inter-co.onelink.me/Qyu7/wcys2pri',
                })
              }}
              className='btn btn-white btn--lg fs-14 fw-600 rounded-2 mx-auto text-orange--extra text-none mt-4 d-flex d-md-none'
            >
              Abra sua conta agora mesmo
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HeroSection
