import React from 'react'
import { ModalWrapper } from './style'
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import QRCodeImage from '../assets/images/qrcode-rlp.png'

type QRCodeModalProps = {
  closeModal: () => void;
  qrCode: string;
}

const QRCodeModal = ({ closeModal, qrCode }: QRCodeModalProps) => {
  return (
    <ModalWrapper>
      <div className='icon-div' onClick={() => closeModal()}>
        <OrangeIcon icon='exit' color='#FF7A00' size='MD' />
      </div>
      <div className='wrapper-div d-flex flex-column align-items-center'>
        <h2 className='fs-24 lh-30 fw-600 text-grayscale--500 mb-5'>Escaneie o QR Code e<br /> ative seu RLP</h2>
        <img src={qrCode} width={200} height={200} />
        <hr className='w-100 mt-5' />
        <p className='fs-14'>Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a tela de ativação do RLP, para que já possa operar com o mesmo ativado.</p>
        <h3 className='fs-16 lh-20 fw-600'>Não tem sua Conta Digital ainda? <a href='/'>Crie a sua conta agora!</a></h3>
      </div>
    </ModalWrapper>
  )
}

export default QRCodeModal
