import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import styled, { keyframes } from 'styled-components'

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`

export const ModalWrapper = styled.div`
  height: 100vh;
  width: 100%;
  z-index: 99;
  background-color: ${ white };  
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  animation: ${animatedModal} 0.5s ease-in-out forwards;
  
  @media ${device.desktopLG} {
    width: 600px;
    float: right;
  }

  h2 {
    font-family: 'Sora';
  }

  .wrapper-div {
    padding: 0 127px;
  }

  .icon-div {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
`
