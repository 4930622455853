import React, { ReactNode } from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import InterResearch from 'inter-frontend-svgs/lib/orangeds/MD/inter-research'
import HomeBroker from 'inter-frontend-svgs/lib/orangeds/MD/home-broker'
import Cashback from 'inter-frontend-svgs/lib/orangeds/MD/cashback'

import usePageQuery from '../../pageQuery'
import { Section, Card } from './style'

import jsonData from './assets/data/data.json'
import { orange } from 'src/styles/colors'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'

const { qrCodeAberturaDeContaRlp } = qrcodeInvestimentsData

type CardType = {
  icon: string;
  title: string;
}

interface IIconsList {
  [index: string]: ReactNode;
}

type AindaNaoInvestePeloInterProps = {
  openModal: () => void;
};

const AindaNaoInvestePeloInter = ({ openModal }: AindaNaoInvestePeloInterProps) => {
  const data = usePageQuery()
  const cardData: CardType[] = jsonData
  const [ sendDatalayerEvent ] = useDataLayer()

  const icons: IIconsList = {
    homeBroker: <HomeBroker color={orange.extra} width={24} height={24} />,
    cashback: <Cashback color={orange.extra} width={24} height={24} />,
    InterResearch: <InterResearch color={orange.extra} width={24} height={24} />,
  }

  return (
    <Section id='hero-rlp' className='bg-grayscale--100 py-5'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 order-md-last mb-4 mb-md-0'>
            <Img fluid={data.bannerAindaNaoInvestePeloInter.fluid} alt='Dedo indicador apontando as oscilações de investimentos' />
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-3'>Ainda não investe <span className='d-xl-block'>pelo Inter?</span></h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-4'>Conheça as vantagens da Inter Invest</p>
            {cardData.map((card: CardType) => (
              <div key={card.icon} className='pb-4'>
                <Card className='d-flex align-items-center h-100'>
                  <div className='svg-div'>
                    {icons[card.icon]}
                  </div>
                  <div>
                    <span className='d-block fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-700 text-grayscale--400 mb-0' dangerouslySetInnerHTML={{ __html: card.title }} />
                  </div>
                </Card>
              </div>
          ))}
            <button
              onClick={() => {
                openModal()
                sendDatalayerEvent({
                  section: 'dobra_13',
                  element_action: 'click button',
                  element_name: 'Abra sua conta',
                  section_name: 'Ainda não investe pelo Inter?',
                })
              }}
              className='btn btn-orange--extra fs-14 fw-700 rounded-2 mx-auto text-white text-none d-none d-md-flex'
            >
              Abra sua conta
            </button>
            <a
              href={qrCodeAberturaDeContaRlp.deeplink}
              onClick={() => {
                openModal()
                sendDatalayerEvent({
                  section: 'dobra_13',
                  element_action: 'click button',
                  element_name: 'Abra sua conta',
                  section_name: 'Ainda não investe pelo Inter?',
                })
              }}
              className='btn btn-orange--extra fs-14 fw-700 rounded-2 mx-auto text-white text-none d-flex d-md-none'
            >
              Abra sua conta
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AindaNaoInvestePeloInter
