import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDomReady from 'src/hooks/window/useDomReady'

import { Modal } from 'src/components/Modal'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import QRCodeModal from './QRCodeModal/_QRCodeModal'

import pageContext from './pageContext.json'

import {
  HeroSection,
  OQueERLP,
  ComoOProvedorDeLiquidezFunciona,
  ConhecaAsVantagens,
  RLPNaInterInvest,
  ComoEFacilAtivarORLP,
  QuandoNegociar,
  AtivosDisponiveisParaOperacao,
  ValoresDasOperacoes,
  ValoresDoMercadoFuturo,
  AcompanheNossoBlog,
  Numeros,
  RLPNaB3,
  ConfiraMargensReduzidas,
  AindaNaoInvestePeloInter,
  Faq,
} from './sections/_index'

import { Wrapper } from './style'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'

const { qrCodeContaKidsAberturaDeContasInvestimentos, qrCodeAberturaDeContaRlp } = qrcodeInvestimentsData

const RLP = () => {
  const domReady = useDomReady()
  const [ modalOpenYourAccount, setModalOpenYourAccount ] = useState(false)
  const [ openQRCodeModal, setOpenQRCodeModal ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_17',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  const handleClickOpenModal = () => {
    setModalOpenYourAccount(!modalOpenYourAccount)
  }

  const handleClickOpenQRCodeModal = () => {
    setOpenQRCodeModal(!openQRCodeModal)
  }

  const openAccountModal = domReady && (
    <Modal
      isModalOpen={modalOpenYourAccount}
      setIsModalOpen={setModalOpenYourAccount}
      locationToRender={document.body}
    >
      {modalOpenYourAccount && (
        <OpenAccountRightForm
          urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink}
          qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode}
          closeModal={() => setModalOpenYourAccount(false)}
          customOrigin='site-institucional:black-friday'
          qrBaixeApp={qrCodeAberturaDeContaRlp.qrcode}
          asFilledLink={qrCodeAberturaDeContaRlp.deeplink}
        />
      )}
    </Modal>
  )

const modalQRCode = domReady && (
  <Modal isModalOpen={openQRCodeModal} setIsModalOpen={setOpenQRCodeModal} locationToRender={document.body}>
    <QRCodeModal closeModal={() => setOpenQRCodeModal(false)} qrCode={qrCodeAberturaDeContaRlp.qrcode} />
  </Modal>
)

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountModal}
        {modalQRCode}
        <HeroSection openModal={handleClickOpenModal} />
        <OQueERLP openModal={handleClickOpenQRCodeModal} />
        <ComoOProvedorDeLiquidezFunciona openModal={handleClickOpenModal} />
        <ConhecaAsVantagens />
        <RLPNaInterInvest openModal={handleClickOpenModal} />
        <ComoEFacilAtivarORLP />
        <QuandoNegociar />
        <AtivosDisponiveisParaOperacao />
        <ValoresDasOperacoes />
        <ValoresDoMercadoFuturo />
        <AcompanheNossoBlog />
        <Numeros />
        <RLPNaB3 />
        <ConfiraMargensReduzidas />
        <AindaNaoInvestePeloInter openModal={handleClickOpenModal} />
        <Faq faqData={pageContext.structuredData} />
        <DisclaimerInterInvest
          sendDatalayerEvent={sendDatalayerEvent}
          dataLayer={dataLayer}
          operationalLink='https://inter.co/inter-dtvm/'
          section='dobra_17'
        />
      </Layout>
    </Wrapper>
  )
}

export default RLP
