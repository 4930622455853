import React, { ReactNode } from 'react'
import usePageQuery from '../../pageQuery'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import InterResearch from 'inter-frontend-svgs/lib/orangeds/MD/inter-research'
import ComputerCall from 'inter-frontend-svgs/lib/orangeds/MD/computer-call'
import MoneyUp from 'inter-frontend-svgs/lib/orangeds/MD/money-up'

import { orange } from 'src/styles/colors'

import jsonData from './assets/data/data.json'

import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'

import { Section, Card } from './style'

const { qrCodeAberturaDeContaRlp } = qrcodeInvestimentsData

type CardType = {
  icon: string;
  title: string;
  description: string;
}

interface IIconsList {
  [index: string]: ReactNode;
}

type RLPNaInterInvestProps = {
  openModal: () => void;
};

const RLPNaInterInvest = ({ openModal }: RLPNaInterInvestProps) => {
  const data = usePageQuery()
  const cardData: CardType[] = jsonData
  const [ sendDatalayerEvent ] = useDataLayer()

  const icons: IIconsList = {
    computerCall: <ComputerCall color={orange.extra} width={24} height={24} />,
    InterResearch: <InterResearch color={orange.extra} width={24} height={24} />,
    moneyUp: <MoneyUp color={orange.extra} width={24} height={24} />,
  }
  return (
    <Section id='hero-rlp' className='py-5'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lg-xl-50 fw-600 text-grayscale--500'>O RLP na Inter Invest</h2>
            {cardData.map((card: CardType) => (
              <div key={card.icon} className='pb-4'>
                <Card className='d-flex h-100'>
                  <div className='svg-div'>
                    {icons[card.icon]}
                  </div>
                  <div>
                    <h3 className='d-block fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-700 text-grayscale--500 mb-1' dangerouslySetInnerHTML={{ __html: card.title }} />
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-0' dangerouslySetInnerHTML={{ __html: card.description }} />
                  </div>
                </Card>
              </div>
          ))}
            <button
              onClick={() => {
                openModal()
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  element_name: 'Abra sua conta e invista',
                  section_name: 'O RLP na Inter Invest',
                })
              }}
              className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-lg-2 mt-xl-4 d-none d-md-flex'
            >
              Abra sua conta e invista
            </button>
            <a
              href={qrCodeAberturaDeContaRlp.deeplink}
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  element_name: 'Abra sua conta e invista',
                  section_name: 'O RLP na Inter Invest',
                })
              }}
              className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-lg-2 mt-xl-4 d-flex d-md-none'
            >
              Abra sua conta e invista
            </a>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 d-none d-md-block'>
            <Img fluid={data.bannerORLPNaInterInvest.fluid} alt='Super App do Inter com sugestões de investimentos do Inter Invest.' />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default RLPNaInterInvest
